<template>
  <div class="headMargin">
    <b-container>
      <b-overlay :show="show" rounded="sm">
        <b-form-group label="Id:">
          <b-form-input v-model="form.id" type="text" disabled></b-form-input>
        </b-form-group>

        <b-form-group label="Email:">
          <b-form-input v-model="form.email" type="text"></b-form-input>
        </b-form-group>

        <b-form-group label="Mobile No:">
          <b-form-input v-model="form.mobile" type="text"></b-form-input>
        </b-form-group>

        <b-form-group label="Created At:">
          <b-form-input v-model="form.created_at" type="text" disabled></b-form-input>
        </b-form-group>

        <b-form-group label="Credits:">
          <b-form-input v-model="form.credits" type="text"></b-form-input>
        </b-form-group>

        <b-form-group label="Normalized Email:">
          <b-form-input v-model="form.normalized_email" type="text"></b-form-input>
        </b-form-group>

        <b-form-group label="UUID:">
          <b-form-input v-model="form.uuid" type="text"></b-form-input>
        </b-form-group>

        <b-form-group label=''>
          <b-form-checkbox v-model="form.valid_email" :value="true" :unchecked-value="false">Valid Email
          </b-form-checkbox>
        </b-form-group>

        <b-button variant="outline-primary" @click="onSave">Save</b-button>

        <b-button v-if="this.type && this.type.indexOf('Edit')>=0" class="m-2" type="Clear"
                  @click="goToSearchScreen" variant="danger">Cancel
        </b-button>
        <b-button v-else class="m-2" type="Clear" @click="clear" variant="danger">Clear</b-button>
      </b-overlay>
    </b-container>
  </div>
</template>

<style>
.headMargin {
  margin-top: 100px;
}
</style>

<script>
import axios from "axios";
import moment from "moment/moment";

const commonUtl = require('../../util/commonUtl');

export default {
  data() {
    return {
      show: false,
      type: '',
      form: {
        id: '',
        email: '',
        mobile: '',
        created_at: '',
        credits: '',
        normalized_email: '',
        uuid: '',
        valid_email: true
      }
    }
  },
  methods: {
    async onSave() {
      this.show = true
      if (this.$route.params.userId) {
        await this.updateUser();
      } else {
        await this.addUser();
      }
      this.show = false
    },
    async goToSearchScreen() {
      await this.$router.push('/Users')
    },
    clear() {
      this.form = {
        email: '',
        mobile: '',
        credits: '',
        normalized_email: '',
        uuid: '',
        valid_email: true
      }
    },
    async addUser() {
      let url = `${process.env.VUE_APP_BASE_URL}/v1/api/admin/user`;
      try {
        await axios.post(url, {...this.form});
        await commonUtl.showNotification(this, 'success', 'User Added Successfully')
        this.$router.push(`/users`)
      } catch (e) {
        const data = e.response.data;
        await commonUtl.showNotification(this, 'error', 'Error', data.value)
      }
    },
    async updateUser() {
      let url = `${process.env.VUE_APP_BASE_URL}/v1/api/admin/user/${this.$route.params.userId}`;
      try {
        this.form.created_at = moment(this.form.created_at).valueOf();
        await axios.put(url, {...this.form});
        await commonUtl.showNotification(this, 'success', 'User Updated Successfully')
      } catch (e) {
        const data = e.response.data;
        await commonUtl.showNotification(this, 'error', 'Error', data.value)
      }
    },
    async getUserDetails() {
      let url = `${process.env.VUE_APP_BASE_URL}/v1/api/admin/user/${this.$route.params.userId}`;
      const {data} = await axios.get(url, {});
      this.form = data.value
      this.form.created_at = this.getDateInStr(data.value.created_at)

      this.type = 'Edit';
    },
    getDateInStr(date) {
      if (!date) {
        return ''
      }
      return moment(new Date(date)).format('DD MMM, YYYY HH:mm')
    },
  },
  mounted() {
    if (this.$route.params.userId) {
      this.getUserDetails();
    }
  }
}
</script>
